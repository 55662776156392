import React from 'react';
import { Link } from 'react-router-dom';

import { LessonLockType } from '@chew/common/domains';

import { getLessonLock } from '@/util/lesson';
import { LessonWithMetadata } from '@/domains';
import { analytics } from '@/services/analytics';

import { Session } from '@/containers/SessionContainer';
import { LessonDuration } from '@/components/Lesson/LessonDuration';

import { LessonThumbnail } from './LessonThumbnail';
import { LessonLockedOverlay, LessonPlayOverlay, LessonProgress } from './LessonOverlay';

interface Props {
  lesson: LessonWithMetadata;
  onLocked(lock: LessonLockType): void;
  level?: number;
}

export const LessonTile: React.FC<React.PropsWithChildren<Props>> = ({ lesson, onLocked, level }) => {
  const { me } = Session.useContainer();

  const lock = getLessonLock({ me, lesson, level });

  const to = `/courses/${lesson.courseId}/lessons/${lesson.id}`;
  const state = lock ? {} : { autoPlay: true };

  const onClick = React.useCallback(
    (event: React.MouseEvent) => {
      if (!lock) {
        analytics.trackEvent('Courses - Click on lesson', { lesson_type: 'unlocked' });
        return;
      }

      analytics.trackEvent('Courses - Click on lesson', {
        lesson_type: 'locked',
        reason: lock === LessonLockType.Availability ? 'progress' : 'subscription'
      });

      event.preventDefault();

      onLocked(lock);
    },
    [lock]
  );

  return (
    <Link {...{ to, state, onClick }} className="block py-2 group">
      <LessonThumbnail src={lesson.image.url} className="relative rounded-2xl">
        {lock ? <LessonLockedOverlay {...{ lock }} /> : <LessonPlayOverlay circleClassName="group-hover:scale-100" />}

        {!lock && (
          <React.Fragment>
            <LessonDuration
              duration={lesson.duration}
              className="absolute right-4 bottom-4 px-2 py-1 text-sm text-purple-medium bg-white rounded-lg"
            />

            {!!lesson.metadata?.progress && <LessonProgress progress={lesson.metadata.progress} />}
          </React.Fragment>
        )}
      </LessonThumbnail>

      <header className="pt-2 font-bold text-[1.125rem]">
        <h2>{lesson.title}</h2>
      </header>

      <p className="text-sm text-purple-medium pt-1 font-inter line-clamp-3">{lesson.description}</p>
    </Link>
  );
};
