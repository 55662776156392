import React from 'react';
import Helmet from 'react-helmet';

import { AppContent } from '@/components/App/AppContent';
import { Session } from '@/containers/SessionContainer';

import './styles/base.sass';
import 'tailwindcss/tailwind.css';
import './assets/fonts/cabinet-grotesk.css';

export const App: React.FC = () => (
  <Session.Provider>
    <Helmet defaultTitle="Chew" titleTemplate="%s - Chew" />

    <AppContent />
  </Session.Provider>
);
