import React from 'react';
import classnames from 'classnames';

import { updateLessonProgress } from '@/services/api/courses';

import { LessonDetail } from '@chew/common/domains';
import { getLessonMetadata, getProgressFromLessonMetadata, getLessonLock } from '@/util/lesson';

import { Session } from '@/containers/SessionContainer';
import { LessonThumbnail } from '@/components/Lesson/LessonThumbnail';
import { LessonPlayOverlay, LessonProgress } from '@/components/Lesson/LessonOverlay';
import { useContinueWatchingLessons } from '@/pages/DashboardPage/hooks/useContinueWatchingLessons';

import { LessonVideoPlayer } from './LessonVideoPlayer';

interface Props extends React.HTMLProps<HTMLElement> {
  autoPlay: boolean;
  lesson?: LessonDetail;
  onWatched(): void;
}

export const LessonVideoSection: React.FC<Props> = ({ lesson, autoPlay, onWatched, ...props }) => {
  const { me, loadMe } = Session.useContainer();
  const continueWatchingLessons = useContinueWatchingLessons();

  const lock = React.useMemo(() => getLessonLock({ me, lesson }), [me, lesson]);

  const metadata = lesson ? getLessonMetadata(continueWatchingLessons, lesson.id) : undefined;
  const progress = getProgressFromLessonMetadata(metadata);

  const [played, setPlayed] = React.useState(autoPlay);

  const onProgressChange = React.useCallback(
    (progress: number) => {
      if (!lesson) return;

      return updateLessonProgress({ ...lesson, metadata: { progress } })
        .then(loadMe)
        .catch(() => null);
    },
    [lesson, loadMe]
  );

  const onEnded = React.useCallback(() => {
    if (!lesson) return;

    return updateLessonProgress({ ...lesson, metadata: { progress: 100 } })
      .then(loadMe)
      .then(() => {
        return onWatched();
      })
      .catch(() => null);
  }, [lesson, loadMe]);

  return (
    <section {...props}>
      <div className={classnames('relative aspect-video', { 'cursor-pointer': !lock })}>
        {!!lesson && (
          <React.Fragment>
            <LessonThumbnail
              src={lesson.image.url}
              className="absolute left-0 top-0 group"
              onClick={() => setPlayed(!lock)}
            >
              <LessonPlayOverlay circleClassName="group-hover:scale-100" />

              <LessonProgress {...{ progress }} />
            </LessonThumbnail>

            {!!played && !!lesson.video?.url && (
              <LessonVideoPlayer
                {...{ lesson, progress, onProgressChange, onEnded }}
                className="absolute left-0 top-0 w-full h-full"
              />
            )}
          </React.Fragment>
        )}
      </div>
    </section>
  );
};
