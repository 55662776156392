import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { LessonLockType } from '@chew/common/domains';
import { Modal, useModal } from '@chew/common/components/Modal';

import { translations } from '@/locales';
import { analytics } from '@/services/analytics';

import { Session } from '@/containers/SessionContainer';
import { Loading, LoadingCenter, LoadingIndicator, LoadingMessage } from '@/components/Loading';
import { PagePadding } from '@/components/Page';
import { LevelFilters } from '@/components/Lesson/LevelFilters';
import {
  Collections,
  CollectionHeading,
  CollectionScrollableContent,
  CollectionScrolledList
} from '@/components/Lesson/LessonCollections';
import { LessonTile } from '@/components/Lesson/LessonTile';
import { PaywallModal } from '@/components/Lesson/PaywallModal';
import { UnavailableModal } from '@/components/Lesson/UnavailableModal';

import { DigitalTaskNotice } from './DigitalTaskNotice';

import { useLoadLevels } from './hooks/useLoadLevels';
import { useLoadCourses } from './hooks/useLoadCourses';
import { useLoadDigitalTask } from './hooks/useLoadDigitalTask';
import { useLevelIdQueryParam } from './hooks/useLevelIdQueryParam';

import carrot from './assets/bg-carrot.png';
import broccoli from './assets/bg-broccoli.png';
import mango from './assets/bg-mango.png';
import pepper from './assets/bg-pepper.png';
import steak from './assets/bg-steak.png';
import onion from './assets/bg-onion.png';

export const CoursesListPage: React.FC = () => {
  const { loadMe } = Session.useContainer();

  const paywallModal = useModal(PaywallModal);
  const unavailableModal = useModal(UnavailableModal);

  const { data: levels = [], isValidating: levelsLoading } = useLoadLevels();
  const [levelId, updateLevelId] = useLevelIdQueryParam(levels);
  const { courses = [], loading: coursesLoading } = useLoadCourses(levelId);
  const { data: digitalTask, mutate: reloadDigitalTask } = useLoadDigitalTask(levelId);

  const loading = (levelsLoading || coursesLoading) && !courses.length;

  const level = React.useMemo(() => levels.find((item) => item.id === levelId)?.lvl as number, [levels, levelId]);

  React.useEffect(() => {
    analytics.trackEvent('navigation', { type: 'Open courses' });
  }, []);

  const onDigitalTaskComplete = React.useCallback(async () => {
    await Promise.all([reloadDigitalTask(), loadMe()]);
  }, [reloadDigitalTask, loadMe]);

  return (
    <main className="py-8">
      <PagePadding>
        <h1 className="relative flex flex-col items-center justify-center w-full py-8 rounded-3xl bg-[#F2E4D4] text-[2rem] font-bold shadow md:py-20">
          <FormattedMessage id={translations.pages.courseList.title} />

          <img
            src={carrot}
            role="presentation"
            className="absolute top-[20%] -right-[2%] w-11 h-11 md:top-[10%] md:right-[15%] md:w-20 md:h-20"
          />
          <img
            src={steak}
            role="presentation"
            className="absolute bottom-[5%] right-[30%] w-9 h-9 md:bottom-[10%] md:w-11 md:h-11"
          />
          <img
            src={broccoli}
            role="presentation"
            className="absolute -top-[20%] left-[35%] w-11 h-11 md:-top-[10%] md:w-16 md:h-16"
          />
          <img
            src={pepper}
            role="presentation"
            className="absolute -bottom-[10%] -left-[7%] w-12 h-12 md:-bottom-[5%] md:left-[10%]"
          />
          <img src={mango} role="presentation" className="hidden absolute top-[25%] left-[1%] w-14 h-14 md:block" />
          <img src={onion} role="presentation" className="hidden absolute top-[50%] -right-[2%] w-10 h-10 md:block" />
        </h1>

        <LevelFilters
          {...{ levels }}
          id={levelId}
          className="py-8"
          onChange={(levelId) => {
            analytics.trackEvent('Courses - Change level', { selectedLevel: level });
            return updateLevelId(levelId);
          }}
        />

        {!!levelId && !!digitalTask && (
          <DigitalTaskNotice {...{ levelId, digitalTask }} onCompleted={onDigitalTaskComplete} />
        )}
      </PagePadding>

      <Collections>
        {courses.map((course) => (
          <React.Fragment key={course.id}>
            <PagePadding>
              <CollectionHeading title={course.title}>
                <Link
                  to={`/courses/${course.id}`}
                  className="font-bold text-orange-light underline"
                  onClick={() => {
                    if (!levelId) return;

                    analytics.trackEvent('Courses - Click on Open full course', {
                      selectedLevel: level
                    });
                  }}
                >
                  <FormattedMessage id={translations.pages.courseList.openCourse} />
                </Link>
              </CollectionHeading>
            </PagePadding>

            <CollectionScrollableContent>
              <PagePadding>
                <CollectionScrolledList>
                  {course.lessons.map((lesson) => (
                    <LessonTile
                      level={level}
                      key={lesson.id}
                      {...{ lesson }}
                      onLocked={(lock) => {
                        switch (lock) {
                          case LessonLockType.Subscription:
                            return paywallModal.open(() => null);
                          case LessonLockType.Availability:
                            return unavailableModal.open(() => null);
                        }
                      }}
                    />
                  ))}
                </CollectionScrolledList>
              </PagePadding>
            </CollectionScrollableContent>
          </React.Fragment>
        ))}
      </Collections>

      <Modal {...paywallModal.props} />
      <Modal {...unavailableModal.props} />

      {!loading && !courses.length && (
        <PagePadding className="text-2xl font-bold">
          <FormattedMessage id={translations.pages.courseList.empty} />
        </PagePadding>
      )}

      <Loading visible={loading} className="relative h-10">
        <LoadingCenter>
          <LoadingMessage>
            <LoadingIndicator />
            <span>Loading courses...</span>
          </LoadingMessage>
        </LoadingCenter>
      </Loading>
    </main>
  );
};
