import { Profile, Lesson, LessonLockType } from '@chew/common/domains';
import { LessonWithMetadata } from '@/domains';

interface GetLessonLockOptions {
  me?: Profile;
  lesson?: Lesson;
  level?: number;
}

export const getLessonLock = ({ me, lesson, level }: GetLessonLockOptions) => {
  if (!me || !lesson) return undefined;

  if (lesson.paid && !me.premiumSubscription) {
    if (!level) return LessonLockType.Subscription;
    if (level && level >= 2) return LessonLockType.Subscription;
  }

  if (!lesson.available) return LessonLockType.Availability;
};

export const getLessonMetadata = (lessons: LessonWithMetadata[], lessonId: number) =>
  lessons.find((lesson) => lesson.id === lessonId)?.metadata;

export const getProgressFromLessonMetadata = (metadata: LessonWithMetadata['metadata'], fallback = 0) =>
  metadata?.progress ?? fallback;

export const serializeLesson = (lesson: LessonWithMetadata): LessonWithMetadata => ({
  id: lesson.id,
  courseId: lesson.courseId,
  title: lesson.title,
  description: lesson.description,
  paid: lesson.paid,
  duration: lesson.duration,
  image: lesson.image,
  points: lesson.points,
  metadata: lesson.metadata,
  available: lesson.available,
  watched: lesson.watched,
  completed: lesson.completed,
  badge: lesson.badge
});
